export const genderData = [
    {
      value: "Ms",
      label: "Ms",
    },
    {
      value: "Mr",
      label: "Mr",
    },
    {
      value: "Mrs",
      label: "Mrs",
    },
  ];