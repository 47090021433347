import React, { useState } from "react";
import { Box, Button, MenuItem, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useMediaQuery } from "@mui/material";
import Header from "../../../components/global/header";
import userApi from "../../../services/userApi";
import { toast } from "react-toastify";
import convertToBase64 from "../../../units/convertToBase64";
import { genderData } from "../../../components/common";

const AddUser = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const phoneRegExp =
    /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;
  const handleFormSubmit = async (values: any, { resetForm }: any) => {
    let payload = {}

    if(values.urlAvatar){

      const base64 = await convertToBase64(values.urlAvatar[0]);
      payload = {
        username: values.email.split("@")[0],
        password: "123456!@#",
        passwordConfirm: "123456!@#",
        firstName: values.firstName,
        lastName: values.lastName,
        mobileNo: values.phone,
        position: values.position,
        email: values.email,
        urlAvatar: base64,
        gender: values.gender,
        nickname: values.nickname,
      }
    }else{
       payload = {
        username: values.email.split("@")[0],
        password: "123456!@#",
        passwordConfirm: "123456!@#",
        firstName: values.firstName,
        lastName: values.lastName,
        mobileNo: values.phone,
        position: values.position,
        email: values.email,
        gender: values.gender,
        nickname: values.nickname,
      }
    }
    const rep = await userApi.postCreateUser(payload);
    if (rep) {
      toast.success("Create success");
      resetForm()
      setUrlPreview("http://localhost:5000/images/brands/dafcLogo.jpg")
    } else {
      toast.warning("Create faild");
    }
  };

  const [urlPreview, setUrlPreview] = useState('http://localhost:5000/images/brands/dafcLogo.jpg')

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    position: "",
    urlAvatar: "",
    nickname: "",
    gender: "",
  };
  const checkoutSchema = yup.object().shape({
    firstName: yup.string().required("Required"),
    lastName: yup.string().required("Required"),
    email: yup.string().email("Invalid email!").required("Required"),
    phone: yup
      .string()
      .matches(phoneRegExp, "phone number is not valid!")
      .required("Required"),
    position: yup.string().required("Required"),
    gender: yup.string().required("Required"),
    nickname: yup.string(),
  });

  return (
    <Box m="20px">
      <Header title="CREATE USER" subtitle="Create a new user profile" />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box display="flex" justifyContent="center" alignItems="center" width={"100%"} mb={3}>
                <img
                  alt="profile-user"
                  width="200px"
                  height="200px"
                  src={ urlPreview }
                  className="cursor-pointer rounded-full object-fill h-100 w-100"
                  style={{height: '200px', width:'200px'}}
                />
                <input
                  type="file"
                  placeholder="Avatar"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue("urlAvatar", e.target.files);
                    URL.revokeObjectURL(urlPreview)
                    const urlPreviewFile = URL.createObjectURL(e.target.files?.[0] ?? new Blob())
                    setUrlPreview(urlPreviewFile)
                  }}
                  name="urlAvatar"
                  className="ml-3"
                />
              </Box>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                // variant="filled"
                select
                label="Gender"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.gender}
                name="gender"
                error={!!touched.gender && !!errors.gender}
                helperText={touched.gender && errors.gender}
                sx={{ gridColumn: "span 2" }}
              >
                {genderData.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Nickname"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.nickname}
                name="nickname"
                error={!!touched.nickname && !!errors.nickname}
                helperText={touched.nickname && errors.nickname}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="First Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.firstName}
                name="firstName"
                error={!!touched.firstName && !!errors.firstName}
                helperText={touched.firstName && errors.firstName}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Last Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.lastName}
                name="lastName"
                error={!!touched.lastName && !!errors.lastName}
                helperText={touched.lastName && errors.lastName}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                name="email"
                error={!!touched.email && !!errors.email}
                helperText={touched.email && errors.email}
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Phone Number"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.phone}
                name="phone"
                error={!!touched.phone && !!errors.phone}
                helperText={touched.phone && errors.phone}
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Position"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.position}
                name="position"
                error={!!touched.position && !!errors.position}
                helperText={touched.position && errors.position}
                sx={{ gridColumn: "span 4" }}
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button type="submit" color="secondary" variant="contained">
                Create New User
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default AddUser;
