import { BrowserRouter, Routes, Route } from "react-router-dom";
import LoginPage from "../pages/auth/loginPage";
import SignUpPage from "../pages/auth/signUpPage";
import PageNotFound from "../pages/pageNotFound";
import DashboardPage from "../pages/dashboard";
import GuestGuard from "../guards/GuestGuard";
import AuthGuard from "../guards/AuthGuard";
import UserList from "../pages/user/list";
import UserEdit from "../pages/user/edit";
import AddUser from "../pages/user/add";
import ProfileCurrent from "../pages/profile";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/auth/login"
          element={
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          }
        />
        <Route
          path="/auth/sign-up"
          element={
            <GuestGuard>
              <SignUpPage />
            </GuestGuard>
          }
        />
        <Route
          path="/"
          element={
            <AuthGuard>
              <DashboardPage />
            </AuthGuard>
          }
        />
        <Route
          path="/profile"
          element={
            <AuthGuard>
              <ProfileCurrent />
            </AuthGuard>
          }
        />
        <Route
          path="/user/add"
          element={
            <AuthGuard>
              <AddUser />
            </AuthGuard>
          }
        />
        <Route
          path="/user/list"
          element={
            <AuthGuard>
              <UserList />
            </AuthGuard>
          }
        />
        <Route
          path="/user/:id"
          element={
            <AuthGuard>
              <UserEdit />
            </AuthGuard>
          }
        />
        <Route
          path="/user/:id/edit"
          element={
            <AuthGuard>
              <UserEdit />
            </AuthGuard>
          }
        />
        <Route
          path="/position/list"
          element={
            <AuthGuard>
              <DashboardPage />
            </AuthGuard>
          }
        />
        <Route
          path="/position/id"
          element={
            <AuthGuard>
              <DashboardPage />
            </AuthGuard>
          }
        />
        <Route
          path="/position/edit"
          element={
            <AuthGuard>
              <DashboardPage />
            </AuthGuard>
          }
        />
        <Route
          path="/position/eid"
          element={
            <AuthGuard>
              <DashboardPage />
            </AuthGuard>
          }
        />

        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
