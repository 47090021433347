import { Box, Button, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../../theme/theme";
import Header from "../../../components/global/header";
import { useEffect, useState } from "react";
import userApi from "../../../services/userApi";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

type CellProp = {
  row: {
    id: any;
  };
};

const UserList = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const columns = [
    { field: "id", headerName: "Id" },
    {
      field: "username",
      headerName: "Username",
      width: 200,
      cellClassName: "name-column--cell",
    },
    {
      field: "firstName",
      headerName: "First Name",
    },
    {
      field: "lastName",
      headerName: "Last Name",
    },
    { field: "mobileNo", headerName: "Phone Number", width: 100 },
    { field: "email", headerName: "Email", width: 200 },
    {
      field: "Edit",
      headerName: "Edit",
      width: 100,
      renderCell: ({ row: { id } }: CellProp) => {
        return (
          <Box
            display={"flex"}
            margin={0}
            p={"5px"}
            width={"100%"}
            borderRadius={"4px"}
            justifyContent={"center"}
            bgcolor={colors.greenAccent[800]}
          >
            <Link to={`/user/${id}/edit`}>
              <Button>
                <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
                  EDIT
                </Typography>
              </Button>
            </Link>
          </Box>
        );
      },
    },
    {
      field: "Delete",
      headerName: "Delete",
      width: 100,
      renderCell: ({ row: { id } }: CellProp) => {
        return (
          <Box
            display={"flex"}
            margin={0}
            p={"5px"}
            width={"100%"}
            borderRadius={"4px"}
            justifyContent={"center"}
            bgcolor={colors.redAccent[700]}
          >
            <Button
              onClick={() => {
                handleDeleteUserById(id,paginationModel.pageSize,paginationModel.page);
              }}
            >
              <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
                Delete
              </Typography>
            </Button>
          </Box>
        );
      },
    },
  ];

  const handleDeleteUserById = async (id: string,paginate:number, page:number) => {
    if (await userApi.deleteUserById(id)) {
      toast.success(`Delete id ${id} success`);
      await getUsers(paginate,page);
    } else {
      toast.warning(`Delete ${id} faild`);
    }
  };

  const [itemCount, setItemCount] = useState(0)
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });

  console.log(itemCount)
  console.log(paginationModel)

  const getUsers = async (paginate:number, page:number) => {
    setIsLoading(true)
    const rep = await userApi.getUsers(paginate,page);
    setUsers(rep.data);
    setItemCount(rep.paginator.itemCount)
    setIsLoading(false)
  };
  useEffect(() => {
    getUsers(paginationModel.pageSize, paginationModel.page);
  }, [paginationModel]);

  if (!users) return <>Not Found</>;

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Users" subtitle="" />
      </Box>
      <Box
        m="8px 0 0 0"
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid 
          rows={users} 
          columns={columns}
          loading={isLoading}
          paginationModel={paginationModel} 
          onPaginationModelChange={setPaginationModel}
          paginationMode="server"
          rowCount={itemCount}
          />
      </Box>
    </Box>
  );
};

export default UserList;
